

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppConfiguration } from 'apps/debtor-portal/src/app/app.configuration';
import { IUser } from 'apps/debtor-portal/src/app/authentication-module/models';
import { ScriptToLoad } from 'apps/debtor-portal/src/app/shared-module/enums';
import { mapToUserAvatar } from 'apps/debtor-portal/src/app/shared-module/functions/mappers.function';
import { IUserAvatar } from 'apps/debtor-portal/src/app/shared-module/models';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';
import { AppLanguage, ICountryListItemTranslated, mapToTranslatedCountries, ICountriesDictionary, mapToCountriesDictionary } from '@aston/foundation';
import { ICurrencyListItemTranslated, mapToTranslatedCurrencies, ICurrenciesDictionary, mapToCurrenciesDictionary } from '@aston/foundation';

import { createEntitySliceSelectors } from '../functions';

import { FeatureName } from './feature';
import { IAppState } from './state';

export const selectState: MemoizedSelector<object, IAppState> = createFeatureSelector<IAppState>(FeatureName);

const isFinished = (state: IAppState): boolean => state.isFinished;
const getConfig = (state: IAppState): AppConfiguration => state.config;
const getCurrentUser = (state: IAppState): IUser => state.user;
const getCurrentUserAvatar = (state: IAppState): IUserAvatar => state.user ? mapToUserAvatar(state.user.fullName) : undefined;
export const getScriptIsLoaded = (state: IAppState, script: ScriptToLoad): boolean => state.scripts && state.scripts[script];

export const selectIsInitFinished = createSelector(selectState, isFinished);
export const selectBootstrapError = createSelector(selectState, state => state.bootstrapError);
export const selectIsReady = createSelector(selectState, state => !state.isLoading);
export const selectConfig = createSelector(selectState, getConfig);
export const selectCurrentUser = createSelector(selectState, getCurrentUser);
export const selectCurrentUserIsSupport = createSelector(selectCurrentUser, user => user?.isSupport);
export const selectCurrentUserAvatar = createSelector(selectState, getCurrentUserAvatar);
export const selectScriptIsLoaded = (script: ScriptToLoad) => createSelector(selectState, state => getScriptIsLoaded(state, script));

export const {
	getEntity: getFactorConfiguration,
	selectEntity: selectFactorConfiguration
} = createEntitySliceSelectors(createSelector(selectState, state => state.factorConfig));

export const {
	selectEntity: selectReferential
} = createEntitySliceSelectors(createSelector(selectState, state => state.referential));

export const selectHasCreditLimit = createSelector(selectReferential, ref => ref?.hasCreditLimit);

export const selectDunningLevels = createSelector(selectReferential, ref => ref?.dunningLevels.map(e => e.dunningLevel) || []);
export const selectClaimNames = createSelector(selectReferential, ref => ref?.claimNames || []);

export const {
	selectEntity: selectCurrentLanguage
} = createEntitySliceSelectors(createSelector(selectState, state => state.language));

export const {
	selectEntity: selectTenant
} = createEntitySliceSelectors(createSelector(selectState, state => state.tenant));

export const selectTenantCurrencyCode = createSelector(selectTenant, tenant => tenant?.currencyCodeInvoicing);

export const getInactivityTime = (state: IAppState): number => {
	return state.userSession.inactivityTime;
};
export const selectInactivityTime = createSelector(selectState, state => getInactivityTime(state));
export const selectTenantLogo = createSelector(selectState, state => state.isPlatformCustomizationEnabled ? state.logo : null);
export const selectSessionId = createSelector(selectState, state => state.sessionId);

export const getInactivityCountDown = (state: IAppState): number => {
	return Math.ceil((AppConstants.MAX_INACTIVITY_TIME - getInactivityTime(state)) / AppConstants.SECOND);
};
export const selectInactivityCountDown = createSelector(selectState, state => getInactivityCountDown(state));

export const getIsInactive = (state: IAppState): boolean => {
	return getInactivityTime(state) >= AppConstants.MAX_INACTIVITY_TIME || !state.user;
};
export const selectIsInactive = createSelector(selectState, state => getIsInactive(state));

export const getShowInactivityWarning = (state: IAppState): boolean => {
	return getInactivityTime(state) >= AppConstants.MAX_INACTIVITY_TIME - AppConstants.INACTIVITY_WARNING_COUNTDOWN_BEFORE_LOGOUT;
};
export const selectShowInactivityWarning = createSelector(selectState, state => getShowInactivityWarning(state));


export const {
	selectIsLoading: selectCountriesIsLoading,
	selectError: selectCountriesError,
} = createEntitySliceSelectors(createSelector(selectState, state => state.countries));

export const getCountries = (state: IAppState, lang: AppLanguage): ICountryListItemTranslated[] => mapToTranslatedCountries(state.countries.entity, lang);
export const selectCountries = (lang: AppLanguage) => createSelector(selectState, state => getCountries(state, lang));
export const getCountriesDictionary = (state: IAppState, lang: AppLanguage): ICountriesDictionary => mapToCountriesDictionary(getCountries(state, lang));
export const selectCountriesDictionary = (lang: AppLanguage) => createSelector(selectState, state => getCountriesDictionary(state, lang));
export const getCurrenciesDictionary = (state: IAppState, lang: AppLanguage): ICurrenciesDictionary => mapToCurrenciesDictionary(getCurrencies(state, lang));
export const selectCurrenciesDictionary = (lang: AppLanguage) => createSelector(selectState, state => getCurrenciesDictionary(state, lang));
export const getCurrencies = (state: IAppState, lang: AppLanguage): ICurrencyListItemTranslated[] => {
	const countries = state.factorConfig?.entity?.currencies;
	return mapToTranslatedCurrencies(countries, lang)
};

export const getCurrenciesByAlphabeticalOrder = (state: IAppState, lang: AppLanguage): ICurrencyListItemTranslated[] => {
	const countries = state.factorConfig?.entity?.currencies;
	return mapToTranslatedCurrencies(countries, lang).map(c => ({ ...c, currencyName: `${c.currencyCode} - ${c.currencyName}` })).sort(function(a,b){
		return a.currencyName.localeCompare(b.currencyName);
	});
};
export const selectCurrencies = (lang: AppLanguage) => createSelector(selectState, state => getCurrenciesByAlphabeticalOrder(state, lang));
export const selectPaymentTypes = createSelector(selectReferential, referential => referential?.paymentTypes.map(t => t.paymentType).filter(t => t.toString() !== 'None'));
