
export type Database = {
	id: string,
	name: string,
}

export type Timezone = string
export type MicrosoftTimezone = string

export const MS_TO_IANA = [
	{ ms: 'Romance Standard Time', iana: 'Europe/Paris' },
	{ ms: 'Central Pacific Standard Time', iana: 'Pacific/Noumea' },
]
export function msToIanaTimezone(ms: MicrosoftTimezone): Timezone {
	const tz = MS_TO_IANA.find(t => t.ms === ms)
	if (!tz) throw new Error(`Unknown Microsoft timezone: ${ms}`)
	return tz.iana;
}
