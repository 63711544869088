import { Locale } from 'date-fns';
import { format as formatFn, toZonedTime } from 'date-fns-tz';
import { formatDistance as formatDistanceFn } from 'date-fns/formatDistance';

import { AppConstants } from '../config/constants.config';
import { DateOnly, msToIanaTimezone } from '../models';

import { toDate } from './utils.date';

const locales: { [key: string]: Locale } = {};
const globalScope = (typeof window === 'undefined' ? global : window) as any;

export function registerDateLocale(code: string, localeObj: Locale) {
	locales[code.toLowerCase()] = localeObj;
}

export function useDefaultDateLocale(code: string) {
	console.debug(`[localization] using locale ${code}`)
	globalScope.__localeId__ = code.toLowerCase();
}

export function useDefaultTimezone(tz: string) {
	globalScope.__ms_api_timezone__ = tz;
	globalScope.__api_timezone__ = msToIanaTimezone(tz);
	globalScope.__api_timezone_not_local__ = globalScope.__api_timezone__ !== Intl.DateTimeFormat().resolvedOptions().timeZone;
	console.debug(`[localization] using timezone ${globalScope.__api_timezone__} (${globalScope.__ms_api_timezone__})`)
}

/**
 * @param date - The original date
 * @param format - The string of tokens
 * @param options - An object with options
 *
 * @returns The formatted date string
 */
export function formatDate<DateType extends Date>(date: DateType | number | string, formatStr = 'PP', timeZone = null) {
	const asDate = toDate(date);
	return formatFn(
		timeZone ? toZonedTime(asDate, timeZone) : asDate,
		formatStr,
		{ locale: locales[globalScope.__localeId__] }
	);
}

export function formatDurationUTC(miliseconds: number) {
	return formatDate(toZonedTime(new Date(miliseconds), 'GMT'), 'pp');
}

export function formatDurationHumanize(miliseconds: number) {
	return formatDistanceFn(0, miliseconds, { includeSeconds: true })
}

// Get the string expected by the API for a date only.
export function toDateOnly(date?: Date): DateOnly {
	return formatDate(date, AppConstants.DATE_ONLY_FORMAT);
}

export function formatDateOnlyToApi<DateType extends Date>(date: DateType | number | string) {
	return formatDate(date, AppConstants.DATE_ONLY_FORMAT);
}

export function formatDateFullToApi<DateType extends Date>(date: DateType | number | string) {
	return formatDate(date, AppConstants.DATE_FULL_FORMAT) + '+00:00';
}
