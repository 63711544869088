/* eslint-disable no-useless-escape */

import { AsyncValidatorFn, ValidatorFn, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UniversalValidators, PasswordValidators } from 'ngx-validators';
import * as IBAN from 'iban';
import { Observable, map, take } from 'rxjs';

import { startOfDay, isValidDate, getDatesToCompare, isAfter, subDays, isBeforeDay, addDays, isAfterDay } from '../functions/utils.date';
import { getType, toNumber } from '../functions/utils.function';
import { AppConstants } from '../config/constants.config';

import { Loader } from './loader.class';

type NotEmptyDefaultValueType = 'boolean' | 'number' | 'string' | 'array' | 'custom' | 'object' | 'unknown';
interface NotEmptyError { type: NotEmptyDefaultValueType; defaultValue?: any; }
interface StrictlyGreaterThanError { strictlyGreaterThan: { min: number; } }
interface StrictlyLessThanError { strictlyLessThan: { max: number; } }
interface ExactLengthError { requiredLength: number; }
interface DaysComparisonError { days: number; }

export class CustomValidators {
	protected static isEmptyInputValue(value: any): boolean {
		// we don't check for string here so it also works with arrays
		// eslint-disable-next-line eqeqeq
		return value == null || value.length === 0;
	}

	public static pass(_: AbstractControl): ValidationErrors | null {
		return null;
	}

	public static notLoading(loader: Loader): AsyncValidatorFn {
		return (_: AbstractControl): Observable<ValidationErrors | null> => {
			return loader.isLoading$.pipe(map(loading => loading ? { loading: true } : null), take(1))
		}
	}

	public static notNull(control: AbstractControl): ValidationErrors | null {
		return Validators.required(control);
	}

	public static notInValues(refusedValues: (number | string)[]): ValidatorFn {
		return (control: AbstractControl) => {
			const refused = [].concat(control.value).filter(v => refusedValues.includes(v));
			return refused.length
				? { notInValues: refused, refusedValues }
				: null
		};
	}

	public static notEmpty(defaultValueForType?: any): ValidatorFn {
		const hasDefaultValue = !!arguments.length;
		return (control: AbstractControl): { 'noEmptyValue': NotEmptyError } | null => {
			const isDefaultValue = (value: any): NotEmptyDefaultValueType => {
				switch (getType(value)) {
					case 'boolean':
						if (value === false) {
							return 'boolean';
						}
						break;
					case 'number':
						if (value === 0) {
							return 'number';
						}
						break;
					case 'bigint':
						// eslint-disable-next-line eqeqeq
						if (value == 0) {
							return 'number';
						}
						break;
					case 'string':
						if (value.trim().length === 0) {
							return 'string';
						}
						break;
					case 'object':
						if (Array.isArray(value) && value.length === 0) {
							return 'array';
						}

						if (CustomValidators.isEmptyInputValue(value)) {
							return 'object';
						}
						break;
					case 'undefined':
					case 'null':
						return 'unknown';
					case 'function':
					case 'symbol':
					default:
						if (CustomValidators.isEmptyInputValue(value)) {
							return 'object';
						}
						break;
				}

				if (hasDefaultValue && value === defaultValueForType) {
					return 'custom';
				}
			};

			const defaultValueType = isDefaultValue(control.value);
			if (defaultValueType) {
				const error: NotEmptyError = {
					type: defaultValueType
				};

				if (defaultValueType === 'custom') {
					error.defaultValue = defaultValueForType;
				}

				return { 'noEmptyValue': error };
			}

			return null;
		};
	}

	public static notNumber(control: AbstractControl): ValidationErrors | null {
		return (control.value !== 0 && !control.value)
			|| (control.value+'').match(/[a-z]/i) ? null : { pattern: true };
	}

	public static number(control: AbstractControl): ValidationErrors | null {
		return UniversalValidators.isNumber(control);
	}

	public static integer(control: AbstractControl): ValidationErrors | null {
		return !(''+control.value).match(/[\., ]/) && Number.isInteger(+control.value) ? null : { numberRequired: true };
	}

	public static numberAllowComma(): ValidatorFn {
		return Validators.pattern(/^[0-9]{1,16}([,\.][0-9]{1,10})?$/);
	}

	public static decimalNumber(numberOfDigits = 2) {
		return Validators.pattern(`^-?[0-9]{1,16}([,\.][0-9]{1,${numberOfDigits}})?$`);
	}

	public static atLeastDigitChar(amount: number): ValidatorFn {
		return PasswordValidators.digitCharacterRule(amount);
	}

	public static atLeastLowercaseChar(amount: number): ValidatorFn {
		return PasswordValidators.lowercaseCharacterRule(amount);
	}

	public static atLeastUppercaseChar(amount: number): ValidatorFn {
		return PasswordValidators.uppercaseCharacterRule(amount);
	}

	public static atLeastSpecialChar(amount: number): ValidatorFn {
		return PasswordValidators.specialCharacterRule(amount);
	}

	public static personName(): ValidatorFn {
		return Validators.pattern(/^[a-zA-Z\u0027\-\u00E8\u00E9\u00E7\u00E0\u00F9\u00F4\u00EA\u00EE\u00EF\u00EB\u00FC\u00F6\u00C8\u00C9\u00C7\u00C0\u00D9\u00D4\u00CA\u00CE\u00CF\u00CB\u00DC\u00D6\s]*$/);
	}

	public static email(expression?: string): ValidatorFn {
		if (expression) {
			return Validators.pattern(expression);
		}

		return Validators.email;
	}

	public static strictlyLessThan(max: number): ValidatorFn {
		return (control: AbstractControl): StrictlyLessThanError | null => {
			if (CustomValidators.isEmptyInputValue(control.value) || control.value < max) {
				return null;
			}

			return { strictlyLessThan: { max } };
		};
	}

	public static lessThan(max: number): ValidatorFn {
		return Validators.max(max - 1);
	}

	public static lessThanOrEqual(inclusiveMax: number): ValidatorFn {
		return Validators.max(inclusiveMax);
	}

	public static strictlyGreaterThan(min: number): ValidatorFn {
		return (control: AbstractControl): StrictlyGreaterThanError | null => {
			if (CustomValidators.isEmptyInputValue(control.value) || +(String(control.value).replace(/,/, '.')) > min) {
				return null;
			}

			return { strictlyGreaterThan: { min } };
		};
	}

	public static greaterThan(min: number): ValidatorFn {
		return Validators.min(min + 1);
	}

	public static greaterThanOrEqual(min: number): ValidatorFn {
		return (control: AbstractControl) => {
			const actual = control.value;
			if (actual === undefined || actual === null || actual === '') return null;
			const actualn = toNumber(actual);
			if (isNaN(actualn) || actualn < min) return { min: { min, actual }}
			return null
		}
	}

	public static minimumLength(min: number): ValidatorFn {
		return Validators.minLength(min);
	}

	public static maximumLength(max: number): ValidatorFn {
		return Validators.maxLength(max);
	}

	public static exactLength(length: number): ValidatorFn {
		return (control: AbstractControl): { exactLength: ExactLengthError } | null => {
			if (CustomValidators.isEmptyInputValue(control.value)) {
				return null;
			}

			const value = control.value;
			if (value && value.length !== length) {
				return { exactLength: { requiredLength: length } };
			}

			return null;
		};
	}

	public static regularExpression(expression: string): ValidatorFn {
		return Validators.pattern(expression);
	}

	public static regularExpressionItems(expression: string): ValidatorFn {
		const validator = Validators.pattern(expression);
		return (control: AbstractControl) => {
			const values = [].concat(control.value);
			const errors = values.reduce((err, value) => ({ ...err, ...validator({...control, value} as AbstractControl)}), {});
			return Object.keys(errors).length ? errors : null;
		}
	}

	public static loaderNotLoading(control: AbstractControl): { noLoadingLoader: boolean } | null {
		if (CustomValidators.isEmptyInputValue(control.value)) {
			return null;
		}

		const value = control.value;
		if (value && value.isLoading) {
			return { noLoadingLoader: true };
		}

		return null;
	}

	public static validDate(control: AbstractControl): { validDate: true } | null {
		if (CustomValidators.isEmptyInputValue(control.value)) {
			return null;
		}

		const value = control.value as Date;

		if (!isValidDate(value)) {
			return { validDate: true };
		}

		return null;
	}

	public static beforeNow(doNotCheckHours: boolean): ValidatorFn {
		return (control: AbstractControl): { beforeNow: boolean } | null => {
			const value = control.value as Date;

			if (CustomValidators.isEmptyInputValue(value)) {
				return null;
			}

			const dates = getDatesToCompare(value, doNotCheckHours);

			if (isAfterDay(dates.dateToTest, dates.now)) {
				return { beforeNow: true };
			}

			return null;
		};
	}

	public static afterNow(doNotCheckHours: boolean): ValidatorFn {
		return (control: AbstractControl): { afterNow: boolean } | null => {
			const value = control.value as Date;

			if (CustomValidators.isEmptyInputValue(value)) {
				return null;
			}

			const dates = getDatesToCompare(value, doNotCheckHours);

			if (isBeforeDay(dates.dateToTest, dates.now)) {
				return { afterNow: true };
			}

			return null;
		};
	}

	public static olderThanDays(numberOfDays: number): ValidatorFn {
		return (control: AbstractControl): { olderThanDays: DaysComparisonError } | null => {
			const value = control.value as Date;

			if (CustomValidators.isEmptyInputValue(value)) {
				return null;
			}

			const dates = getDatesToCompare(value, true);
			const limit = addDays(dates.now, numberOfDays);

			if (isBeforeDay(dates.dateToTest, limit)) {
				return { olderThanDays: { days: numberOfDays } };
			}

			return null;
		};
	}

	public static notOlderThanDays(numberOfDays: number): ValidatorFn {
		return (control: AbstractControl): { notOlderThanDays: DaysComparisonError } | null => {
			const value = control.value as Date;

			if (CustomValidators.isEmptyInputValue(value)) {
				return null;
			}

			const dates = getDatesToCompare(value, true);
			const limit = subDays(dates.now, numberOfDays);

			if (isBeforeDay(value, limit)) {
				return { notOlderThanDays: { days: numberOfDays } };
			}

			return null;
		};
	}

	public static youngerThanDays(numberOfDays: number): ValidatorFn {
		return (control: AbstractControl): { youngerThanDays: DaysComparisonError } | null => {
			const value = control.value as Date;

			if (CustomValidators.isEmptyInputValue(value)) {
				return null;
			}

			const dates = getDatesToCompare(value, true);
			const limit = addDays(dates.now, numberOfDays);

			if (isBeforeDay(dates.dateToTest, limit)) {
				return { youngerThanDays: { days: numberOfDays } };
			}

			return null;
		};
	}

	public static exactInitialDateOrGreaterOrEqualsNow(initialDate: Date): ValidatorFn {
		return (control: AbstractControl): { exactInitialDateOrGreaterOrEqualsNow: boolean } | null => {
			if (CustomValidators.isEmptyInputValue(control.value)) {
				return null;
			}

			const _value = control.value as Date;
			const value = startOfDay(_value).getTime();
			const limit = startOfDay(new Date).getTime();
			const min = startOfDay(initialDate).getTime();

			if (value && value !== min && value < limit) {
				return { exactInitialDateOrGreaterOrEqualsNow: true };
			}

			return null;
		};
	}

	public static iban(control: AbstractControl): { iban: boolean } | null {
		if (CustomValidators.isEmptyInputValue(control.value)) {
			return null;
		}

		if (!IBAN.isValid(control.value)) {
			return { iban: true };
		}

		return null;
	}

	public static frenchIban(control: AbstractControl): { frenchIban: boolean } | null {
		if (CustomValidators.isEmptyInputValue(control.value) || !IBAN.isValid(control.value)) {
			return null;
		}

		if (!IBAN.isValidBBAN('FR', IBAN.toBBAN(control.value))) {
			return { frenchIban: true };
		}

		return null;
	}

	public static betweenNumericalValidator(pattern: string): ValidatorFn {
		return (control: AbstractControl) => {
			const value = control.value;

			if (!value) { return null; }

			if (!(!value[0] || value[0].match(pattern)) || !(!value[1] || value[1].match(pattern))) {
				return { pattern: true };
			}

			if (!value[0] || !value[1]) {
				return { required: true };
			}

			if (Number(value[0]) > Number(value[1])) {
				return { rangeInconsistency: true };
			}

			return null;
		};
	}

	public static dateExpressionValidator(control: AbstractControl) {
		const value = control.value;

		// type checking because of value versatility
		if (!value || typeof value !== 'string') { return null; }

		if (!value.trim().match(AppConstants.DATE_EXPRESSION_REGEX)) {
			return { pattern: true };
		}

		return null;
	}


	public static betweenDateExpressionValidator (control: AbstractControl) {
		const value = control.value;

		if (!value) { return null; }

		// type checking because of value versatility
		if (!value[0] || !value[1] || typeof value[0] !== 'string' || typeof value[1] !== 'string') {
			return { required: true };
		}

		if (!value[0].trim().match(AppConstants.DATE_EXPRESSION_REGEX) || !value[1].trim().match(AppConstants.DATE_EXPRESSION_REGEX)) {
			return { pattern: true };
		}

		if (isAfter(value[0], value[1])) {
			return { rangeInconsistency: true };
		}

		return null;
	}
}
