
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader, UnsubscriberClass } from '@aston/foundation';
import { take, withLatestFrom } from 'rxjs/operators';
import * as RoutesDefinitions from 'apps/debtor-portal/src/app/routes-definitions';
import { Store } from '@ngrx/store';

import { AuthenticationService } from '../../services';
import { IAuthParams } from '../../models';
import * as AppStoreSelectors from '../../../root-store/app-store/selectors';

@Component({
	selector: 'login-page',
	templateUrl: './login-page.component.html'
})
export class LoginPageComponent extends UnsubscriberClass implements OnInit {

	formLoader = new Loader();

	constructor(
		protected authenticationService: AuthenticationService,
		protected activatedRoute: ActivatedRoute,
		protected store: Store,
		protected router: Router) {
		super();
	}

	ngOnInit() {
		const authParams = this.activatedRoute.snapshot.params;
		const query = this.activatedRoute.snapshot.queryParams;

		console.info('Starting auth check with params', authParams); // eslint-disable-line no-restricted-syntax

		this.authenticationService.login(authParams as IAuthParams).pipe(
			withLatestFrom(this.store.select(AppStoreSelectors.selectTenant)),
			take(1))
			.subscribe(([auth, tenant]) => {
			if (!auth) return this.redirect();

			if (query['dunningActionId']) {
				return this.router.navigateByUrl(RoutesDefinitions.getDunningAccountingFullPath(+query['dunningActionId']))
			}
			else if (query['invoicingTab'] && tenant.isManualInvoiceEnabled) {
				return this.router.navigateByUrl(RoutesDefinitions.getDunningInvoicingFullPath())
			}

			// User Story 32084: 💰[ACC][Facturation][Options] Impact de la désactivation de l'option "Module facturation"
			// Add correct redirection in this case

			// default redirect to home
			this.router.navigateByUrl(RoutesDefinitions.getAccountingListFullPath())
		});
	}

	redirect() {
		this.router.navigateByUrl(RoutesDefinitions.getAuthenticationIssueFullPath())
	}
}
