@if (model.isLoading$ | async) {
	<div class="loader loader-{{ size }}" [class.revert]="revertStyle">
		@if (spinning$ | async) {
			<svg class="loader-spinner" viewBox="0 0 50 50">
				<circle class="loader-spinner-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
			</svg>
		}
		@if (loadingMessage) {
			<div class="loader-message" [translate]="loadingMessage"></div>
		}
	</div>
}

@if ((model.error$ | async) && (onLine$ | async)) {
	<ngb-alert type="danger" class="pre" [dismissible]="false">
		{{ errorMessage || model.errorMessage }}
	</ngb-alert>
}
	

