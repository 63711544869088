import { EventEmitter } from '@angular/core';
import { identity } from 'rxjs';

import { ActionType } from '../enums';
import { IAction, IListState } from '../models';
import { ISort, IFilter } from '../models';


type Resettable = {
	load: CallableFunction;
	reset: CallableFunction;
};

export class ListActionHandlerBase {
	public isFilterBuilderOpened = false;

	action: EventEmitter<IAction> = new EventEmitter();

	private pagingDispatcher: CallableFunction = identity;
	private layoutFilterBuilder: Resettable = { load: identity, reset: identity };
	private createFilterDispatcher: (value: IFilter[]) => any = identity;
	private deleteFilterDispatcher: (value: IFilter[]) => any = identity;

	withPagingDispatcher(pagingDispatcher: CallableFunction) {
		this.pagingDispatcher = pagingDispatcher;
		return this;
	}

	withFilterBuilder(
		layoutFilterBuilder: any,
		createFilterDispatcher: (value: IFilter[]) => any = identity,
		deleteFilterDispatcher: (value: IFilter[]) => any = identity
	) {
		this.layoutFilterBuilder = layoutFilterBuilder;
		this.createFilterDispatcher = createFilterDispatcher;
		this.deleteFilterDispatcher = deleteFilterDispatcher;
		return this;
	}

	withTriggers(service: { useHandler(handler: ListActionHandlerBase) }) {
		service.useHandler(this);
		return this;
	}

	setSort(sorts: ISort<any> | ISort<any>[]) {
		this.action.emit({ type: ActionType.SORT, value: sorts });
		this.dispatchPaging({ sorts: Array.isArray(sorts) ? sorts : [sorts] });
	}

	setPageNumber(page: number) {
		this.action.emit({ type: ActionType.SET_PAGE_NUMBER, value: page });
		this.dispatchPaging({ page });
	}

	setPageSize(pageSize: number) {
		this.action.emit({ type: ActionType.SET_PAGE_SIZE, value: pageSize });
		this.dispatchPaging({ pageSize });
	}

	dispatchPaging<SortProp>(props: IListState<SortProp>) {
		if (this.pagingDispatcher) {
			this.pagingDispatcher(props);
		}
	}

	handleFiltersAction(action: IAction) {
		switch (action.type) {
			case ActionType.TOGGLE:
				this.isFilterBuilderOpened = !this.isFilterBuilderOpened;
				if (!this.isFilterBuilderOpened) {
					this.layoutFilterBuilder.reset();
				}
				break;

			case ActionType.OPEN:
				this.layoutFilterBuilder.load(action.value);
				this.isFilterBuilderOpened = true;
				break;

			case ActionType.CLOSE:
				this.layoutFilterBuilder.reset();
				this.isFilterBuilderOpened = false;
				break;

			case ActionType.CREATE: {
				const filters: IFilter[] = [].concat(action.value);
				this.createFilterDispatcher(filters);
				break;
			}

			case ActionType.DELETE: {
				const filters: IFilter[] = [].concat(action.value);
				this.deleteFilterDispatcher(filters);
				break;
			}
		}

		this.action.emit(action);
	}
}
