

import { ReplaySubject, Observable, of, zip, EMPTY } from 'rxjs';
import { map, catchError, switchMap, filter } from 'rxjs/operators';
import { ValidatorFn, AbstractControl } from '@angular/forms';

import { pascalCaseToCamelCase, camelCaseToPascalCase } from '../functions/utils.function';
import { IValidationRuleset, IValidationValidator, IValidationPropertyValidator, IFormValidation, IFormFieldsValidation, IFormValidations, ValidationHelperResult } from '../models/validation.model';

import { ControlValidators } from './control-validators.class';

const debug = (...args) => window['__aston_debug_core'] && console.debug(...args)


export abstract class ValidationService {

    private isLoading: boolean;
    protected domainValidators: IFormValidations;
    protected validationRuleset: IValidationRuleset;
    protected validationRulesetSubject: ReplaySubject<IValidationRuleset> = new ReplaySubject(1);
    readonly validationRuleset$: Observable<IValidationRuleset> = this.validationRulesetSubject.asObservable();

    public loadValidationRuleset(): Observable<any> {

        this.isLoading = true;

        // loading will be set to false by a call to useValidationRuleset
        return this.loadValidationRulesetInternal();
    }

    public setValidationRulesetInError(): void {
        this.isLoading = false;
        this.validationRulesetSubject.error('[validators] Form validation rules retrieving failed');
    }

    public abstract loadValidationRulesetInternal(): Observable<any>;

    protected useValidationRuleset(rulesetApi: IValidationRuleset, domainValidators: IFormValidations) {
        this.domainValidators = domainValidators;
        const shouldWarnOnUnknowRulesets = Object.keys(rulesetApi).length >= 10; // ugly test for anonymous ruleset
        this.validationRuleset = Object.entries(domainValidators).reduce((rulesetLocal, [validatorName, validatorNameApi]) => {
            const rulesetName = rulesetApi[validatorNameApi.ruleset] ? validatorNameApi.ruleset : camelCaseToPascalCase(validatorNameApi.ruleset);

            if (!rulesetApi[rulesetName]) {
                if (shouldWarnOnUnknowRulesets) debug(`[validators] "${rulesetName}" is not a known validation ruleset.`);
                return rulesetLocal;
            }
            // Merge each entity ruleset in the global registry
            return {...rulesetLocal, [validatorName]: this.buildEntityValidators(rulesetApi, rulesetName)}
        }, {});

        this.isLoading = false;
        this.validationRulesetSubject.next(this.validationRuleset);
    }

    private buildEntityValidators(ruleset: IValidationRuleset, validatorNameApi: string): IValidationValidator {
        return Object.entries(ruleset[validatorNameApi])
            .filter(([propertyName]) => !!propertyName)
            .reduce((accValidators, [propertyName, propertyValidators]) => {
            accValidators = {
                ...accValidators,
                // Build this entity validators
                [camelCaseToPascalCase(propertyName)]: propertyValidators,
            };

            // patch it with subvalidators if any
            propertyValidators
            .filter(propertyValidator => propertyValidator.name === 'ChildValidatorAdaptor')
            .map(propertyValidator => this.combineValidationDescriptors(ruleset, accValidators, pascalCaseToCamelCase(propertyValidator.additionalData.childValidatorName), camelCaseToPascalCase(propertyName) + '.'));

            return accValidators;
        }, {} as IValidationValidator);
    }

    private combineValidationDescriptors(ruleset: IValidationRuleset, parentValidator: IValidationValidator, childValidatorName: string, prefix = ''): IValidationValidator {
        let entries = [];

        if (ruleset[childValidatorName]) {
            entries = Object.entries(ruleset[childValidatorName]);

        } else if (ruleset[camelCaseToPascalCase(childValidatorName)]) {
            entries = Object.entries(ruleset[camelCaseToPascalCase(childValidatorName)]);
        }

        for (const [childPropertyName, childPropertyValidators] of entries) {
            const fullPropertyName = prefix + camelCaseToPascalCase(childPropertyName);

            let existingValidators = parentValidator[fullPropertyName];
            if (!existingValidators) {
                existingValidators = [];
            }

            for (const childPropertyValidator of childPropertyValidators) {
                if (childPropertyValidator.additionalData && !!childPropertyValidator.additionalData.childValidatorName) {
                    this.combineValidationDescriptors(
                        ruleset,
                        parentValidator,
                        pascalCaseToCamelCase(childPropertyValidator.additionalData.childValidatorName),
                        fullPropertyName + '.');
                }
                existingValidators.push(childPropertyValidator);
            }

            parentValidator[fullPropertyName] = existingValidators;
        }
        return parentValidator;
    }

    getValidationRuleset(scope: string): Observable<IValidationValidator> {
        return this.validationRuleset$.pipe(
            filter(_ => !this.isLoading),
            map(ruleset => ruleset[scope]),
            catchError(() => of(<IValidationValidator> {}))
        );
    }

    getValidationRules(scope: string): IValidationValidator {
        return this.validationRuleset[pascalCaseToCamelCase(scope)];
    }

    getValidationPropertyRules(scope: string|IValidationValidator, prop: string): IValidationPropertyValidator[] {
        if (typeof scope === 'string') {
            return this.getValidationRules(scope)[prop];
        }

        return scope[prop];
    }

    getHelper(formDescriptor: IFormValidation): Observable<[ValidationServiceHelper, IFormFieldsValidation, string]> {
        return this.validationRuleset$.pipe(
            filter(_ => !this.isLoading),
            map(_ => Object.keys(this.domainValidators).find(k => this.domainValidators[k] === formDescriptor)),
            switchMap(scopeKey => {
                return this.getValidationRuleset(scopeKey).pipe(
                    map(validator => {
                        if (!validator) {
                            const error = new Error(`[validators] Cannot create an helper from an empty validator, "${formDescriptor.ruleset}" is not known.`);
                            console.error(error);
                            throw error;
                        }
                        return [new ValidationServiceHelper(validator), formDescriptor.fields, scopeKey] as [ValidationServiceHelper, IFormFieldsValidation, string]
                    }) // Casting is needed
                );
            })
        );
    }

    getHelpers(...formDescriptors: IFormValidation[]): Observable<ValidationHelperResult[]> {
        return zip(...formDescriptors.map(fd => this.getHelper(fd)));
    }
}

export class ValidationServiceHelper {

    constructor(private ruleset: IValidationValidator) {
    }

    validationRuleData(apiEntityName: string, apiRuleName: string) {
        const rule = this.validationRules(apiEntityName).find(e => e.name === apiRuleName);
        return rule?.additionalData;
    }

    validationRules(apiEntityRuleName: string) {
        if (this.ruleset && apiEntityRuleName in this.ruleset) {
            return this.ruleset[apiEntityRuleName];
        }
        debug(`[validators] "${apiEntityRuleName}" is not a known property of this validator.`);
        return [];
    }

    control(field: AbstractControl, apiEntityRuleName: string, rules: ValidatorFn[] = []): ValidationServiceHelper {
        const apiValidationRules = this.validationRules(apiEntityRuleName);
        ControlValidators.createControlValidators(field, rules, apiValidationRules);
        return this;
    }
}

export type FormHelpers = {
    helper: ValidationServiceHelper;
    fields: IFormFieldsValidation;
}

export const provideMockValidationService = (helper: Observable<ValidationHelperResult[]> = EMPTY) => [
    { provide: ValidationService, useValue: { getHelper: () => helper } },
]
